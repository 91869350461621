import config from '../config'
import axios from "axios";

const buildHeader = (token) => {return { 'x-token' : token  }};
const doRequest = (method, url, body, extraHeaders)=>{
    //console.log(method, url);
    return new Promise(function (resolve, reject) {
        let request = new XMLHttpRequest();
        request.open(method, url);
        let noContentType = true;
        if(extraHeaders) {
            for(let k in extraHeaders) {
                if(k.toLowerCase() === 'content-type') {
                    noContentType = false;
                }
                request.setRequestHeader(k, extraHeaders[k]);
            }
        }
        if(noContentType && body) {
            request.setRequestHeader('Content-Type', 'application/json');
        }
        request.addEventListener("load", ( e ) => {
            const status = e.target.status;
            let reply;
            try {
                reply = JSON.parse(e.target.responseText);
            } catch (e) {
                reply = {}
            }
            if(status === 200) {
                resolve(reply);
                return;
            }
            if(_.isString(reply)) {
                reply = { message: reply }
            }

            reply.status =  e.target.status;
            reject( reply );

        }, false);
        request.addEventListener("error",( e ) => {
            const status = e.target.status;
            let reply;
            try {
                reply = JSON.parse(e.target.responseText);
            } catch (e) {
                reply = { status : "fail", message : "errore sconosciuto"}
            }
            reply.statusCode = status;
            reject( reply );
        }, false);
        if(body) {
            request.send(JSON.stringify(body));
        } else{
            request.send();
        }
    })
};
const doFormRequest = (method, url, body, extraHeaders)=>{
    //console.log(method, url);
    return new Promise(function (resolve, reject) {
        let request = new XMLHttpRequest();
        request.open(method, url);
        if(extraHeaders) {
            for(let k in extraHeaders) {
                request.setRequestHeader(k, extraHeaders[k]);
            }
        }
        request.addEventListener("load", ( e ) => {
            const status = e.target.status;
            let reply;
            try {
                reply = JSON.parse(e.target.responseText);
            } catch (e) {
                reply = { error : e };
            }
            if(status === 200) {
                resolve(reply);
                return;
            }
            if(_.isString(reply)) {
                reply = { message: reply }
            }

            reply.status =  e.target.status;
            reject( reply );
        }, false);
        request.addEventListener("error",( e ) => {
            let reply;
            try {
                reply = JSON.parse(e.target.responseText);
            } catch (e) {
                reply = { error : e };
                console.log(e)
            }
            reply.status =  e.target.status;
            reject( reply );
        }, false);
        if(body) {
            request.send(body);
        } else{
            request.send();
        }
    })
};


const index = {
    login : async (email, password) => {
        const reply = await axios.post(`${config.server}/login`, { email, password })
        return reply.data
    },
    heartbeat : async (token) => {
        const reply = await axios.get(`${config.server}/heartbeat`, { headers : buildHeader(token) })
        return reply.data
    },
    profile : async (token) => {
        const reply = await axios.get(`${config.server}/profile`, { headers : buildHeader(token) })
        return reply.data
    },
    restLoad : async ( token, collection, query, limit, page, sort, order ) => {
        let url = `${config.server}/api/${collection}?limit=${limit}&page=${page - 1}`;
        for(const k in query) {
            if(query[k])
                url += "&"+k+"="+query[k];
        }
        if(sort) {
            url += "&sort="+sort;
            url += "&order=" + (order === "DESC" ? "DESC" : "ASC");
        }
        const reply = await axios.get(url, { headers : buildHeader(token) })
        return reply.data
    },
    restGet : async ( token, collection, id ) => {
        let url = id ? `${config.server}/api/${collection}/${id}` : `${config.server}/api/${collection}`;
        const reply = await axios.get(url, { headers : buildHeader(token) })
        return reply.data
    },
    restInsert : async ( token, collection, data ) => {
        let url = `${config.server}/api/${collection}`;
        const reply = await axios.post(url, data, { headers : buildHeader(token) })
        return reply.data
    },
    restUpdate : async ( token, collection, data, noId ) => {
        let url = noId || !data._id ? `${config.server}/api/${collection}` : `${config.server}/api/${collection}/${data._id}`;
        const reply = await axios.put(url, data, { headers : buildHeader(token) })
    },
    restDelete : async ( token, collection, id ) => {
        let url = `${config.server}/api/${collection}/${id}`;
        const reply = await axios.delete(url, { headers : buildHeader(token) })
        return reply.data
    },
    upload : async (token, where, formData) => {
        let url = `${config.server}/api/${where}`;
        const reply = await axios.post(url, formData, { headers : buildHeader(token), 'content-type' : 'multipart/form-data' })
        return reply.data
    },


};
export default index;